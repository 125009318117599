<!-- #881 만다라트, B tv 하드 코딩 -->
<template>
  <div class="finished" v-if="['361ed91a5a61', '25bb429692d7'].includes(state.form.name)">
    <div class="card">
      <div class="card-header">
        <b>제출 완료</b>
        <div class="black">신청서 제출을 완료하였습니다.</div>
        <div>
          <div class="black">이어서 후원형 프로젝트 신청을 진행해 주세요.</div>
          <div class="warning">*프로젝트 신청까지 완료해 주셔야 담당 매니저가 배정되어 정상 진행됩니다.</div>
          <div class="go">
            <router-link :to="`/apply/rewardIntro`" class="font-sm pointer-u color-anchor">
              <span class="btn">프로젝트 신청하러 가기</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="finished" v-else>
    <div class="card">
      <div class="card-header">
        <b>제출 완료</b>
        <div>신청서 제출을 완료하였습니다.</div>
        <div class="action pt-3" v-if="state.form.constraintFlag === 'Y'">
          <router-link :to="`/form/categories/${state.form.categoryId}`" class="font-sm pointer-u color-anchor">
            <span>다른 유형 신청하러 가기</span>
          </router-link>
        </div>
        <div class="sub" v-else-if="state.loaded">
          <span>영업일 기준 7일 이내로 심사 결과를 안내해드리겠습니다.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import ApplicationItem from "@/pages/form/ApplicationItem";
import Anchor from "@/components/Anchor";
import http from "@/scripts/http";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageFormHome";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor, ApplicationItem},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.loaded = false;
      http.get(`/api/forms/${router.app.$route.params.name}`).then(({data}) => {
        state.loaded = true;
        state.form = data.body;
        state.form.name = router.app.$route.params.name;
      });
    });

    const state = reactive({
      loaded: false,
      form: {
        categoryId: 0,
        constraintFlag: "N"
      }
    });

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.finished {
  .card {
    border: 0;
    background: none;

    .card-header {
      background: none;
      padding: $px220 $px30;
      border-bottom: 0;
      height: $px500;
      text-align: center;

      b {
        font-size: $px20;
        font-weight: 900;
      }

      div {
        padding-top: $px5;
        font-size: $px16;
        color: #a1a1a1;

        &.sub {
          font-size: $px12;
        }

        &.black {
          color: $colorTextDefault;
          font-size: $px14;
        }
      }

      .btn {
        margin-top: $px20;
        padding: $px16 $px24;
      }

      .warning {
        font-size: $px13;
        color: $colorDanger;
        font-weight: 500;
      }

      .go {
        text-align: center;
        position: relative;

        .btn {
          padding: $px10 $px15;
          background-color: $colorPoint;
          color: white;
        }
      }
    }
  }
}
</style>